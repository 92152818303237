import { styled } from 'twin.macro';

const DivStyled = styled.div`
  &.v-ytbPage {
    &-container {
      /* margin-top: 70px; */
      font-size: 1rem;
    }
    &--remove {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .v-ytbPage__search {
    .v-searchPage {
      &--row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }
    }
  }
  .v-ytbPage {
    padding: 30px;
    display: flex;
    gap: calc(1.6rem * var(--multiplierFontSize));
    flex-direction: column-reverse;
    /* background: #ededed; */
    &--ads {
      height: auto;
    }
    &__search {
      padding: 0;
      .v-ytbPage,
      .v-searchPage--container {
        padding: 0 15px;
        margin: 0;
      }
      .v-searchPage--title {
        p {
          margin-top: 0 !important;
        }
      }
      padding: 0;
    }
    &--row {
      &:not(.has-error) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }
    }
    &--name {
      &-ribbon {
        font-size: calc(1.8rem * var(--multiplierFontSize));
        color: #9b9b9b;
      }
    }
    &--loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &--navigation {
      position: relative;
      margin-bottom: calc(4rem * var(--multiplierFontSize));
      padding: 0;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #242424;
      flex-wrap: wrap;
      font-size: calc(1.6rem * var(--multiplierFontSize));
      min-height: 56px;
      &-btn {
        &-category {
          padding: calc(1.2rem * var(--multiplierFontSize)) 15px;
          color: #fff;
          height: 100%;
          background: #242424;
          cursor: pointer;
          min-height: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 20px;
          }
        }
      }
      &--category {
        position: absolute;
        transform: translate(0px, 46px);
        top: 0;
        z-index: 3;
        max-width: 452px;
        border: none;
        background: #141a23;
        color: #e4ebee;
        width: 100%;
        margin-top: 11px;
        .panel-collapse {
          border: 1px solid transparent;
        }
        .panel-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          padding: 30px 0;
          padding-left: calc(3.2rem * var(--multiplierFontSize));
          .btn {
            &:focus,
            &:active,
            &:hover {
              background: none;
              border: none;
              color: #e4ebee;
            }
            border: none;
            color: #e4ebee;
            background: #141a23;
            display: flex;
            align-items: center;
            font-size: 24px;
          }
        }
        hr {
          width: 90%;
          margin-top: 0;
          margin-bottom: 0;
          color: #c7c5c5;
          background: #c7c5c5;
        }
        .panel-body {
          padding-left: calc(4rem * var(--multiplierFontSize));
          color: #e4ebee;
          .checkbox {
            padding: 15px 0;
            margin: 0;
          }
        }
      }
      &--form {
        input[type='checkbox'] {
          margin-left: calc(-3.2rem * var(--multiplierFontSize));
          transform: scale(1.075);
        }
      }
      &--disable {
        pointer-events: none;
        color: #aeafb0;
        font-weight: bolder;
      }
      &__link {
        color: rgb(247, 244, 243, 0.7);
        padding: calc(1.6rem * var(--multiplierFontSize)) calc(2.4rem * var(--multiplierFontSize));
        text-align: center;
        align-items: center;
        display: flex;
        font-size: 16px;
        &:hover,
        &.active {
          text-decoration: none;
          color: rgb(247, 244, 243);
          /* background: #000000; */
        }
        &.active {
          background: #222a36;
        }
      }
    }
    &--searchInput {
      position: absolute;
      bottom: 0;
      margin-bottom: calc(-4rem * var(--multiplierFontSize));
      margin-left: 15px;
      width: calc(100% - 30px);
      max-width: calc(100% - 30px);
      padding: 0;
      height: 35px;
      border-radius: 35px;
      background-color: #c8d4db;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #c8d4db;
      svg {
        cursor: 'pointer';
        color: #627280;
      }
      input {
        border: none;
        font-size: calc(1.6rem * var(--multiplierFontSize));
        color: #627280;
        background: #c8d4db;
        width: 90%;
        padding-left: calc(1.6rem * var(--multiplierFontSize));
      }
    }
    &--hr {
      margin: calc(1.6rem * var(--multiplierFontSize)) 0;
      border: 0;
      border-top: 1px solid #d9d9d9;
      width: 95%;
    }
    &--filter-history {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #f5f5f5;
      border-top: #d9d9d9;
      flex-wrap: wrap;
      font-size: 10px;
      overflow: hidden;
      margin-bottom: 14px;
      &-title {
        margin: 0;
        color: #e5e5e5;
      }
      button {
        margin: calc(1.4rem * var(--multiplierFontSize));
        font-size: 9px;
        padding: 2px 14px;
        border-radius: 5px;
        color: #627280;
        background: #e5e5e5;
        border: 1px solid #ededed;
      }
    }
    &--col {
      padding: 0;
      /* margin-bottom: 40px; */
    }

    &--container {
      cursor: pointer;
      &:hover {
        .v-ytbPage--container-imgSrc {
          transform: scale(1.1);
          overflow: hidden;
        }
        .v-ytbPage--container-progress {
          transform: scale(1);
        }
      }
      position: relative;
      &-badgeYoutube {
        position: absolute;
        left: calc(1.2rem * var(--multiplierFontSize));
        width: calc(4.5rem * var(--multiplierFontSize));
        bottom: calc(1.2rem * var(--multiplierFontSize));
        img {
          min-height: 0;
          width: 100%;
          height: auto;
          transform: scale(1) !important;
          &:hover {
            transform: scale(1);
          }
        }
      }
      &-badgeVip {
        font-weight: bolder;
        font-size: calc(1.4rem * var(--multiplierFontSize));
        position: absolute;
        top: 10%;
        right: 0;
        padding: calc(0.4rem * var(--multiplierFontSize)) calc(1.6rem * var(--multiplierFontSize));
        color: #e8b600;
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.6);
      }
      &-badge {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 3%;
        right: 3%;
        width: 100%;
        flex-direction: row-reverse;
        &-live {
          font-weight: 700;
          font-size: calc(1.4rem * var(--multiplierFontSize));
          padding: calc(0.1rem * var(--multiplierFontSize)) calc(1.4rem * var(--multiplierFontSize));
          text-transform: uppercase;
          color: #ff0000;
          background: #fff;
          border-radius: 20px;
        }
        &-vip {
          margin-right: calc(1.2rem * var(--multiplierFontSize));
          background-color: #000000;
          position: relative;
          font-weight: bolder;
          font-size: calc(1.4rem * var(--multiplierFontSize));
          padding: calc(0.4rem * var(--multiplierFontSize)) calc(1.6rem * var(--multiplierFontSize));
          color: #e8b600;
        }
      }
      &-image-wrapper {
        margin-bottom: calc(1.4rem * var(--multiplierFontSize));
        width: 100%;
        padding: 0;
        border: none;
        overflow: hidden;
        border-radius: 6px;
        position: relative;
        background: #fff;
        padding-top: calc(9 / 16 * 100%);
        display: block;
        &.poster {
          padding-top: calc(40 / 27 * 100%);
        }
      }
      &-imgSrc {
        display: block;
        max-width: 100%;
        width: 100%;
        transition: all 200ms ease-out;
        transform: scale(1);
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: top;
      }
      &-progress {
        height: calc(0.4rem * var(--multiplierFontSize));
        border-radius: 0px 0px 15px 15px;
        margin: 0;
        transform: scale(1);
        .progress-bar {
          background: #2574d4;
        }
      }

      &-new-release {
        position: absolute;
        top: 0;
        z-index: 998;
        width: calc(8rem * var(--multiplierFontSize));
        height: calc(4rem * var(--multiplierFontSize));
        background: #00b2ff;
        margin-left: calc(-2.8rem * var(--multiplierFontSize));
        transform: rotate(333deg);
        margin-top: calc(-3.2rem * var(--multiplierFontSize));
        display: flex;
        justify-content: center;
        align-items: flex-end;
        left: calc(-1.25rem * var(--multiplierFontSize));
        color: #fff;
        font-weight: 600;
        p {
          margin: 0 0 5px;
        }
        &--epg {
          &::before {
            content: '${props => props.theme.main}';
            position: absolute;
            bottom: calc(-0.35rem * var(--multiplierFontSize));
            width: 45%;
            font-size: calc(0.5625rem * var(--multiplierFontSize));
            height: auto;
            background: #fff;
            right: 0;
            transform: translateX(-23%);
            color: #2574d4;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &-icon-remove {
        display: flex;
        font-size: calc(1.6rem * var(--multiplierFontSize));
        justify-content: space-between;
        height: calc(4rem * var(--multiplierFontSize));
        padding: 0 calc(1.4rem * var(--multiplierFontSize));
        font-weight: 500;
        color: #333333;
        button {
          background: #000000;
          color: #fff;
          max-width: calc(2.8rem * var(--multiplierFontSize));
          max-height: calc(2.8rem * var(--multiplierFontSize));
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover,
          &:focus {
            background: #bd0202;
            border: #bd0202;
          }
          &:focus {
            background: #000000;
            border: #000000;
            outline: none;
          }
        }
      }
    }
    &--rightSite {
      display: none;
    }
    &--leftSite {
      padding: 0;
      .filterMobile {
        &--toggle {
          margin: 0 0;
        }
      }
      .v-season--loadMore {
        width: 100%;
        background: none;
      }
    }
    &--remove {
      &-title {
        color: #283237;
        font-size: calc(2.4rem * var(--multiplierFontSize));
        line-height: calc(3.2rem * var(--multiplierFontSize));
        font-weight: 500;
      }
      &-desciption {
        color: #495963;
        font-size: calc(1.6rem * var(--multiplierFontSize));
        padding: 0 calc(1.6rem * var(--multiplierFontSize));
      }
      &-btn {
        width: 100%;
        padding: calc(1.4rem * var(--multiplierFontSize));
        color: #666666;
        margin-top: calc(2.4rem * var(--multiplierFontSize));
        font-weight: 600;
        border-color: #666666;
        &:hover,
        &:focus {
          background: ${({ theme }) => theme.primaryColor};
          border-color: ${({ theme }) => theme.primaryColor};
          color: #ffffff;
        }
      }
    }
    &--messageError {
      color: red;
      font-size: calc(1.25rem * var(--multiplierFontSize));
      padding: calc(1.6rem * var(--multiplierFontSize)) 0;
    }
  }

  @media (min-width: 768px) {
    .v-ytbPage {
      padding-inline: 45px;
      &--searchInput {
        width: calc(100%);
        input {
          width: 95%;
        }
      }
      &--hr {
        margin: calc(1.6rem * var(--multiplierFontSize)) 0 calc(1.4rem * var(--multiplierFontSize))
          0;
        background: #d9d9d9;
        color: #d9d9d9;
        width: calc(100% - 15px);
      }
    }
    &--row {
      &:not(.has-error) {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }
    }
    .v-ytbPage__search {
      .v-searchPage {
        /* &--col {
          flex-basis: 33.33%;
        } */
        &--row {
          &:not(.has-error) {
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .v-ytbPage {
      flex-direction: row;
      &--row {
        &:not(.has-error) {
          grid-template-columns: repeat(4, 1fr);
          gap: 30px;
        }
      }
      &--searchInput {
        position: relative;
        margin-left: auto;
        margin-bottom: 0;
        max-width: calc(20rem * var(--multiplierFontSize));
        width: 100%;
        padding: calc(0.4rem * var(--multiplierFontSize)) calc(1.4rem * var(--multiplierFontSize));
        input {
          color: #000000;
          width: 100%;
        }
        &:before {
          /* content: ''; */
          width: 1px;
          height: 20px;
          background: #8c8c8c;
          position: absolute;
          left: -20px;
        }
      }
      &--col {
        /* margin-bottom: 30px; */
      }
      &--rightSite {
        padding-left: 0px;
        display: block;
        &.col-lg-4 {
          width: 21em;
        }
        .v-ribbon--vertical {
          margin-top: 0;
        }
      }
      &--filter-history {
        font-size: 13px;
        button {
          font-size: 13px;
          padding: 3px 24px;
        }
        margin-bottom: 24px;
      }
      &--navigation {
        &--category {
          width: 452px;
        }
        background: none;
        border-top: 1px solid rgb(255, 255, 255, 0.1);
        margin: 0 15px;
        margin-bottom: 0;
      }
      &--leftSite {
        .filterMobile {
          &--toggle {
            margin: 0 0 15px;
          }
        }
        &.col-lg-8 {
          width: calc(100% - 21em);
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .v-ytbPage {
      &--row {
        &:not(.has-error) {
          grid-template-columns: repeat(5, 1fr);
        }
      }
    }
    .v-ytbPage__search {
      .v-searchPage {
        &--row {
          &:not(.has-error) {
            grid-template-columns: repeat(5, 1fr);
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .v-ytbPage {
      &--row {
        &:not(.has-error) {
          grid-template-columns: repeat(6, 1fr);
        }
      }
    }
    .v-ytbPage__search {
      .v-searchPage {
        &--row {
          &:not(.has-error) {
            grid-template-columns: repeat(6, 1fr);
          }
        }
      }
    }
  }
`;

export default DivStyled;
