import * as actions from './actions';
import * as homePageApi from '@/api/homePageApi';
import pushAnalytics from '../../services/analytics/Analytics';

export { updateListRibbon, deleteProgress, updatePageYoutube, updateHistoryFilter, search };

function updateListRibbon(data) {
  return dispatch => {
    dispatch(actions.updateListRibbon(data));
  };
}

function updatePageYoutube(data) {
  return dispatch => {
    dispatch(actions.updatePageYoutube(data));
  };
}

function updateHistoryFilter(data) {
  return dispatch => {
    dispatch(actions.updateHistoryFilter(data));
  };
}

function search(params, pageSlug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      pushAnalytics('search', { search_term: params.keyword });
      homePageApi
        .searchYoutubePage(params, pageSlug)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function deleteProgress(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      homePageApi
        .deleteProgress(data.items.id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
